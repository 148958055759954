import useLatest from "@react-hook/latest";
import { DevLogger } from "dev-logger";
import {
  ArrayBufferTarget,
  FileSystemWritableFileStreamTarget,
  Muxer,
  StreamTarget,
} from "mp4-muxer";
import { useCallback, useMemo, useRef } from "react";

import {
  EXPORT_CHECKPOINT,
  STATUS_SUCCESS,
  STATUS_ERROR,
  EXPORT_TYPE_CLIENT,
} from "~/constants/mixpanel.constants";
import { useAnalytics } from "~/hooks/useAnalytics";

export type OutputTarget = ArrayBufferTarget | StreamTarget | FileSystemWritableFileStreamTarget;

export interface UseVideoCreatorProps<T extends OutputTarget> {
  onFinish: (output: T) => void;
  onError: (error: Error) => void;
  projectId?: string;
}

type CodecName = "avc" | "hevc" | "vp9" | "av1";
interface CodecItem {
  name: CodecName;
  registry: string;
}

const FRAMES_BETWEEN_KEYFRAMES = 60;

const logger = new DevLogger("[video-creator]");

/**
 * List of codecs to try to use for encoding. These are attempted in order
 * and the first one that succeeds will be used, so we start with the highest
 * level (6.2) and go down to the lowest that supports 4k 60fps (5.2).
 */
const CODECS_TO_TRY: CodecItem[] = [
  {
    name: "avc",
    registry: "avc1.42003e",
  },
  {
    name: "avc",
    registry: "avc1.42003d",
  },
  {
    name: "avc",
    registry: "avc1.42003c",
  },
  {
    name: "avc",
    registry: "avc1.420034",
  },
];

/**
 * A hook to create an MP4 video from a series of frames.
 *
 * @param onError - Callback to be called when an error occurs
 * @param onFinish - Callback to be called when the video creation is finished
 * @returns Object with functions to start, finish, cancel and add frames to the video.
 */
export function useVideoCreator<T extends OutputTarget>({
  onError,
  onFinish,
  projectId,
}: UseVideoCreatorProps<T>) {
  const muxer = useRef<Muxer<T> | null>(null);
  const videoEncoder = useRef<VideoEncoder | null>(null);

  const latestOnFinish = useLatest(onFinish);
  const latestOnError = useLatest(onError);
  const encodedFrames = useRef(0);
  const extraTrackingInfo = useRef<Record<string, unknown>>({});

  const { track } = useAnalytics();

  const handleEncodingError = useCallback(
    (error: Error) => {
      if (!muxer.current || !videoEncoder.current) {
        // These are only null on two conditions:
        // 1 - We have not finished initializing, in which case the start function will handle
        //     the error and call the error callback if appropriate.
        // 2 - We have already finished, in which case the error will not affect the output, so
        //     we ignore it to prevent interrupting the overall process. These might happen when
        //     attempting to close the encoder.
        return;
      }
      latestOnError.current(error);
    },
    [latestOnError]
  );

  const start = useCallback(
    async (
      target: T,
      options: Omit<VideoEncoderConfig, "avc" | "codec">,
      trackingInfo?: Record<string, unknown>
    ) => {
      if (muxer.current || videoEncoder.current) {
        throw new Error("Video creation already started!");
      }
      logger.log("Starting video creation");
      encodedFrames.current = 0;
      extraTrackingInfo.current = trackingInfo ?? {};
      let codecName: CodecName | undefined = undefined;
      const encoderParams: VideoEncoderInit = {
        error: handleEncodingError,
        output: (chunk, metadata) => {
          muxer.current?.addVideoChunk(chunk, metadata);
        },
      };
      // Try to initialize the encoder with the codecs in order, stopping at the first one that
      // works.
      let latestErrorMessage: string | undefined;
      for (const codec of CODECS_TO_TRY) {
        try {
          // VideoEncoder.isConfigSupported gives a false positive for some codecs, so we try to
          // initialize the encoder and catch any errors that might happen.
          videoEncoder.current = await buildVideoEncoder(encoderParams, {
            ...options,
            codec: codec.registry,
          });
          codecName = codec.name;
          logger.log("Succeded in initializing video encoder", {
            ...options,
            codec: codec.registry,
          });
          track(
            EXPORT_CHECKPOINT,
            {
              checkpoint: "init_video_encoder",
              export_type: EXPORT_TYPE_CLIENT,
              project_id: projectId,
              status: STATUS_SUCCESS,
              codecName: codec.name,
              codec: codec.registry,
              encoder_options: options,
              ...extraTrackingInfo.current,
            },
            ["mixpanel"]
          );
          break;
        } catch (e) {
          // Ignore errors for now, only error out if all codecs fail
          latestErrorMessage = e instanceof Error ? e.message : `${e}`;
        }
      }
      if (!codecName || !videoEncoder.current) {
        latestOnError.current(new Error("Failed to initialize video encoder"));
        track(
          EXPORT_CHECKPOINT,
          {
            checkpoint: "init_video_encoder",
            export_type: EXPORT_TYPE_CLIENT,
            status: STATUS_ERROR,
            encoder_options: options,
            error: latestErrorMessage,
            ...extraTrackingInfo.current,
          },
          ["mixpanel"]
        );
        return;
      }
      muxer.current = new Muxer<T>({
        target,
        video: {
          codec: codecName,
          width: options.width,
          height: options.height,
        },
        fastStart: "in-memory",
        firstTimestampBehavior: "offset",
      });
    },
    [handleEncodingError, latestOnError, projectId, track]
  );

  const finish = useCallback(() => {
    const encoder = videoEncoder.current;
    if (!muxer.current || !encoder) {
      latestOnError.current(new Error("No video being created"));
      return;
    }
    logger.log("Finishing video creation");
    // Flush the encoder to ensure all frames were sent to the muxer, to then finalize it.
    encoder
      .flush()
      .then(() => {
        logger.log("Finishing muxer");
        muxer.current!.finalize();
        tryClose(encoder);
        logger.log("Creation finished!");
        latestOnFinish.current(muxer.current!.target);
        muxer.current = null;
        videoEncoder.current = null;
      })
      .catch((error) => {
        handleEncodingError(new Error("Error while finalizing the video", { cause: error }));
      });
  }, [handleEncodingError, latestOnError, latestOnFinish]);

  const cancel = useCallback(() => {
    tryReset(videoEncoder.current);
    tryClose(videoEncoder.current);
    muxer.current = null;
    videoEncoder.current = null;
  }, []);

  const addFrame = useCallback(async (frame: VideoFrame) => {
    if (!videoEncoder.current) {
      throw new Error("Video encoder is not initialized");
    }
    encodedFrames.current = encodedFrames.current + 1;
    const keyFrame = encodedFrames.current % FRAMES_BETWEEN_KEYFRAMES === 0;
    videoEncoder.current?.encode(frame, { keyFrame });
    if (keyFrame) {
      // Flushing on every keyframe to keep memory usage low
      await videoEncoder.current?.flush();
    }
  }, []);

  return useMemo(
    () => ({
      start,
      finish,
      cancel,
      addFrame,
    }),
    [start, finish, cancel, addFrame]
  );
}

/**
 * Build a video encoder with the given configuration.
 * @param init - Initialization parameters for the encoder
 * @param options - Configuration for the encoder
 * @returns A promise that resolves to a VideoEncoder instance, or rejects with an error if the
 *         encoder could not be initialized.
 */
async function buildVideoEncoder(init: VideoEncoderInit, options: VideoEncoderConfig) {
  const encoder = new VideoEncoder(init);
  encoder.configure(options);
  // Flushing the encoder ensures that the configuration is applied, and any errors are thrown.
  await encoder.flush();
  return encoder;
}

function tryReset(obj?: { reset(): void } | null) {
  try {
    obj?.reset();
  } catch {
    // Ignore errors while closing the object, e.g. if it has already been closed
  }
}

function tryClose(obj?: { close(): void } | null) {
  try {
    obj?.close();
  } catch {
    // Ignore errors while closing the object, e.g. if it has already been closed
  }
}
