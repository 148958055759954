import useLatest from "@react-hook/latest";
import { createCanvas } from "canvas-utils";
import {
  AnimationItem,
  AnyCanvasRenderingContext2D,
  BackgroundEffect,
  CaptionEngine,
  CaptionStyle,
  CaptionStylePreset,
  FilterEffect,
  FrameBorderItem,
  ImageFontEffect,
  OverlayEffect,
  PagSequencePainterItem,
  ScenePositionFactor,
  ShakeData,
  VideoPixelMappings,
  ZoomPoint,
} from "captions-engine";
import { DevLogger } from "dev-logger";
import { ArrayBufferTarget } from "mp4-muxer";
import { useCallback, useMemo, useRef, useState } from "react";
import { unstable_batchedUpdates } from "react-dom";

import {
  EXPORT_CHECKPOINT,
  EXPORT_TYPE_CLIENT,
  STATUS_ERROR,
  STATUS_SUCCESS,
} from "~/constants/mixpanel.constants";
import { useAnalytics } from "~/hooks/useAnalytics";
import { CaptionPage } from "~/modules/project/utils/captionProcessing";
import { createCaptionEngine } from "~/modules/project/utils/createCaptionEngine";
import { RenderOverlayAssets } from "~/modules/project/utils/renderOverlay";
import {
  Clip,
  getRelativeTimestamp,
  shiftLastClipTimestamp,
  isAbsoluteTimestampDeleted,
} from "~/utils/videoClips";

import { useFrameSource } from "./useFrameSource";
import { useVideoCreator } from "./useVideoCreator";

// ENABLE THIS TO TEST CLIENT SIDE EXPORTS WITH OVERLAY AND FRAME BORDERS
// Mock code (start)
// import { useMockOverlayEffects } from "../../modules/project/hooks/useMockOverlayEffects";
// Mock code (end)

type ExporterStatus = "idle" | "rendering" | "rendering-done" | "encoding" | "encoding-done";

export interface ExporterJob {
  video: URL | string | Blob;
  captions?: {
    pages: CaptionPage[];
    style: CaptionStyle;
    stylePreset: CaptionStylePreset;
    languageCode?: string;
  };
  targetDimensions?: { width: number; height: number };
  renderAssets?: RenderOverlayAssets;
  pixelMappings?: VideoPixelMappings[];
  frameBorders?: FrameBorderItem[];
  animationItems?: AnimationItem[];
  zoomPoints?: ZoomPoint[];
  cameraShake?: ShakeData[];
  scenePositionFactors?: ScenePositionFactor[];
  backgroundEffects?: BackgroundEffect[];
  overlayEffects?: OverlayEffect[];
  clips?: Clip[];
  isLandscape?: boolean;
  filters?: FilterEffect[];
  imageFonts?: ImageFontEffect[];
  pagSequences?: PagSequencePainterItem[];
}

export interface ExporterProps {
  onError: (error: Error) => void;
  projectId?: string;
}

const logger = new DevLogger("[video-exporter]");

/**
 * Hook that creates the video to be used for exports, rendering captions, effects and dealing with
 * reframing and clips.
 *
 * @remarks This does not create the final asset since it does not deal with audio.
 * @remarks Uses `useFrameSource` to extract frames from the source video and `useVideoCreator` to
 * encode them into a video file asset.
 * @param onError - Callback to be called when an error occurs.
 * @see useFrameSource
 * @see useVideoCreator
 */
export function useExporter({ onError, projectId }: ExporterProps) {
  const [state, setState] = useState<ExporterStatus>("idle");
  const [renderFrame, setRenderFrame] = useState<number>(0);
  const [videoNumFrames, setVideoNumFrames] = useState<number>(0);
  const [encodedVideoBuffer, setEncodedVideoBuffer] = useState<ArrayBuffer>();

  const { track } = useAnalytics();

  // ENABLE THIS TO TEST CLIENT SIDE EXPORTS WITH OVERLAY AND FRAME BORDERS
  // Mock code (start)
  // const { coolOverlayEffect, grainOverlayEffect } = useMockOverlayEffects();
  // Mock code (end)

  const outputCanvas = useRef<HTMLCanvasElement | OffscreenCanvas | null>(null);
  const currentJob = useRef<ExporterJob | undefined>(undefined);
  const currentEngine = useRef<CaptionEngine | undefined>(undefined);
  const extraTrackingInfo = useRef<Record<string, unknown>>({});

  const latestOnError = useLatest(onError);
  const handleError = useCallback((error: Error) => {
    latestOnError.current(error);
    currentJob.current = undefined;
    currentEngine.current?.destroy();
    currentEngine.current = undefined;
    videoCreator.cancel();
    frameSource.cancel();
    setState("idle");
  }, []);

  const videoCreator = useVideoCreator<ArrayBufferTarget>({
    onError: handleError,
    onFinish: (output) => {
      setState("encoding-done");
      setEncodedVideoBuffer(output.buffer);
      currentJob.current = undefined;
      console.log(output, output.buffer);
    },
    projectId,
  });

  const frameSource = useFrameSource({
    onStart: async (videoInfo) => {
      const job = currentJob.current;
      if (!job) {
        handleError(new Error("No export job ongoing"));
        return;
      }
      setVideoNumFrames(videoInfo.numFrames);
      let engine = currentEngine.current;
      if (!engine) {
        try {
          engine = createCaptionEngine();
        } catch (error) {
          track(
            EXPORT_CHECKPOINT,
            {
              checkpoint: "created_caption_engine",
              export_type: EXPORT_TYPE_CLIENT,
              status: STATUS_ERROR,
              project_id: projectId,
              error: error instanceof Error ? error.message : "error creating caption engine",
              video_width: videoInfo.dimensions.width,
              video_height: videoInfo.dimensions.height,
              job_target_width: job.targetDimensions?.width,
              job_target_height: job.targetDimensions?.height,
              ...extraTrackingInfo.current,
            },
            ["mixpanel"]
          );
          throw error;
        }
        track(
          EXPORT_CHECKPOINT,
          {
            checkpoint: "created_caption_engine",
            export_type: EXPORT_TYPE_CLIENT,
            status: STATUS_SUCCESS,
            project_id: projectId,
            video_width: videoInfo.dimensions.width,
            video_height: videoInfo.dimensions.height,
            job_target_width: job.targetDimensions?.width,
            job_target_height: job.targetDimensions?.height,
            ...extraTrackingInfo.current,
          },
          ["mixpanel"]
        );
      }

      const area = {
        width: job.targetDimensions?.width ?? videoInfo.dimensions.width,
        height: job.targetDimensions?.height ?? videoInfo.dimensions.height,
      };
      currentEngine.current = engine;
      try {
        engine.setWebGLEnabled(true);
        engine.setStrictMode(true);
        engine.setArea(area.width, area.height);
        engine.setVideoPixelMappings(job.pixelMappings ?? []);
        engine.setImages(job.renderAssets?.images ?? []);
        await engine.setCaptions(
          job.captions?.pages ?? [],
          job.captions?.languageCode || "en-US",
          job.isLandscape
        );
        await engine.setTransitions(job.renderAssets?.transitions ?? []);
        engine.setFrameBorders(job.frameBorders ?? []);
        await engine.setAnimations(job.animationItems ?? []);
        engine.setZoomPoints(job.zoomPoints ?? []);
        engine.setCameraShake(job.cameraShake ?? []);
        engine.setPositionFactors(job.scenePositionFactors ?? []);
        await engine.setBackgroundEffects(job.backgroundEffects ?? []);
        await engine.setOverlayEffects(job.overlayEffects ?? []);
        await engine.setWatermark(job.renderAssets?.watermark ?? null);
        engine.setFilters(job.filters ?? []);
        await engine.setImageFonts(job.imageFonts ?? []);
        await engine.setPagSequences(job.pagSequences ?? []);
        if (job.captions) {
          engine.setStyle(job.captions.stylePreset, job.captions.style);
        }
      } catch (error) {
        track(
          EXPORT_CHECKPOINT,
          {
            checkpoint: "set_caption_engine_properties",
            export_type: EXPORT_TYPE_CLIENT,
            status: STATUS_ERROR,
            project_id: projectId,
            error:
              error instanceof Error ? error.message : "error setting caption engine properties",
            video_width: videoInfo.dimensions.width,
            video_height: videoInfo.dimensions.height,
            job_target_width: job.targetDimensions?.width,
            job_target_height: job.targetDimensions?.height,
            ...extraTrackingInfo.current,
          },
          ["mixpanel"]
        );
        throw error;
      }
      track(
        EXPORT_CHECKPOINT,
        {
          checkpoint: "set_caption_engine_properties",
          export_type: EXPORT_TYPE_CLIENT,
          status: STATUS_SUCCESS,
          project_id: projectId,
          video_width: videoInfo.dimensions.width,
          video_height: videoInfo.dimensions.height,
          job_target_width: job.targetDimensions?.width,
          job_target_height: job.targetDimensions?.height,
          ...extraTrackingInfo.current,
        },
        ["mixpanel"]
      );
      // ENABLE THIS TO TEST CLIENT SIDE EXPORTS WITH OVERLAY AND FRAME BORDERS
      // Mock code (start)
      // captionEngine.setOverlayEffects(grainOverlayEffect ? [grainOverlayEffect] : []);
      // await captionEngine.setFrameItems([
      //   {
      //     id: "cinematic_filmBorder-vintage_a",
      //     startTime: 0,
      //     endTime: 7,
      //   },
      // ]);
      // Mock code (end)
      if (!outputCanvas.current) {
        outputCanvas.current = createCanvas(area.width, area.height);
      } else {
        outputCanvas.current.width = area.width;
        outputCanvas.current.height = area.height;
      }
      const ctx = outputCanvas.current.getContext("2d") as AnyCanvasRenderingContext2D | null;
      if (!ctx) {
        track(
          EXPORT_CHECKPOINT,
          {
            checkpoint: "set_caption_engine_context",
            export_type: EXPORT_TYPE_CLIENT,
            project_id: projectId,
            status: STATUS_ERROR,
            video_width: videoInfo.dimensions.width,
            video_height: videoInfo.dimensions.height,
            job_target_width: job.targetDimensions?.width,
            job_target_height: job.targetDimensions?.height,
            output_canvas_width: outputCanvas.current.width,
            output_canvas_height: outputCanvas.current.height,
            ...extraTrackingInfo.current,
          },
          ["mixpanel"]
        );
        throw new Error("Failed to create the 2D context for the video output");
      }
      await engine.setContext(ctx);
      track(
        EXPORT_CHECKPOINT,
        {
          checkpoint: "set_caption_engine_context",
          export_type: EXPORT_TYPE_CLIENT,
          project_id: projectId,
          status: STATUS_SUCCESS,
          video_width: videoInfo.dimensions.width,
          video_height: videoInfo.dimensions.height,
          job_target_width: job.targetDimensions?.width,
          job_target_height: job.targetDimensions?.height,
          output_canvas_width: outputCanvas.current.width,
          output_canvas_height: outputCanvas.current.height,
          ...extraTrackingInfo.current,
        },
        ["mixpanel"]
      );
      await videoCreator
        .start(
          new ArrayBufferTarget(),
          {
            width: area.width,
            height: area.height,
            bitrate: 10_000_000, // 10 Mbps
          },
          extraTrackingInfo.current
        )
        .catch(handleError);
    },
    onFrame: async (frame) => {
      const engine = currentEngine.current;
      const job = currentJob.current;
      if (!engine || !job) {
        handleError(new Error("No export job ongoing"));
        return;
      }
      const frameTime = frame.timestamp / 1e6;
      if (isAbsoluteTimestampDeleted(job.clips, frameTime)) {
        return;
      }
      const absoluteFrameTime = shiftLastClipTimestamp(job.clips, frameTime);
      const outputTimestamp = getRelativeTimestamp(job.clips, absoluteFrameTime);
      engine.setVideoFrame(frame.image);
      await engine.setTimestamp(outputTimestamp);
      await engine.draw(0, 0);
      setRenderFrame(frame.frameNumber);

      // new video frame timestamp and duration (in microseconds)
      const outputFrameOpts: VideoFrameInit = {
        timestamp: outputTimestamp * 1e6,
        duration: frame.duration ?? undefined,
      };

      // create a new frame from our output canvas to pass into our video encoder
      const outputFrame = new VideoFrame(outputCanvas.current!, outputFrameOpts);

      // add the frame to the video encoder
      await videoCreator.addFrame(outputFrame).finally(() => outputFrame.close());
    },
    onFinish: () => {
      setState("encoding");
      videoCreator.finish();
    },
    onError: handleError,
    projectId,
  });

  const progress = useMemo(() => {
    if (state === "idle") {
      return 0;
    }
    if (state === "rendering" && videoNumFrames === 0) {
      return 0;
    }
    if (state === "rendering") {
      return (0.9 * renderFrame) / videoNumFrames;
    }
    if (state === "rendering-done") {
      return 0.9;
    }
    if (state === "encoding") {
      return 0.95;
    }
    return 1;
  }, [state, renderFrame, videoNumFrames]);

  const reset = useCallback(() => {
    logger.log("Resetting the exporter!");
    unstable_batchedUpdates(() => {
      setEncodedVideoBuffer(undefined);
      setRenderFrame(0);
      setVideoNumFrames(0);
      setState("idle");
    });
    videoCreator.cancel();
    frameSource.cancel();
    currentJob.current = undefined;
    currentEngine.current?.destroy();
    currentEngine.current = undefined;
  }, [frameSource, videoCreator]);

  const start = useCallback(
    (job: ExporterJob, trackingInfo?: Record<string, unknown>) => {
      if (currentJob.current) {
        throw new Error("Another job is being executed");
      }
      extraTrackingInfo.current = trackingInfo ?? {};
      currentJob.current = job;
      setState("rendering");
      frameSource.start(job.video, extraTrackingInfo.current);
    },
    [frameSource]
  );

  return {
    state,
    renderFrame,
    progress,
    videoNumFrames,
    encodedVideoBuffer,
    reset,
    start,
  };
}
