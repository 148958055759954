import { DevLogger } from "dev-logger";
import { ArrayBufferTarget, MuxerOptions } from "mp4-muxer";
import mp4box, { DataStream, MP4ArrayBuffer, MP4File, MP4Info, MP4Sample, MP4Track } from "mp4box";

const logger = new DevLogger("[demuxer]");

// Demuxes the first video track of an MP4 file using MP4Box
class MP4FileSink implements UnderlyingSink<Uint8Array> {
  private readonly _setStatus: ((type: string, message: string) => void) | undefined;
  private readonly _file: MP4File;
  private _offset: number = 0;

  constructor(file: MP4File, setStatus: ((type: string, message: string) => void) | undefined) {
    this._file = file;
    this._setStatus = setStatus;
  }

  write(chunk: Uint8Array) {
    // MP4Box.js requires buffers to be ArrayBuffers, but we have a Uint8Array.
    const buffer: MP4ArrayBuffer = new ArrayBuffer(chunk.byteLength) as MP4ArrayBuffer;
    buffer.byteLength;
    new Uint8Array(buffer).set(chunk);

    // Inform MP4Box where in the file this chunk is from.
    buffer.fileStart = this._offset;
    this._offset += buffer.byteLength;

    // Append chunk.
    this._setStatus?.("fetch", (this._offset / 1024 ** 2).toFixed(1) + " MiB");
    this._file.appendBuffer(buffer);
  }

  close() {
    this._setStatus?.("fetch", "Done");
    this._file.flush();
  }
}

export interface MP4VideoTrackDimensions {
  width: number;
  height: number;
  rawWidth: number;
  rawHeight: number;
  transform: number[];
}

export interface MP4DemuxerOptions {
  onConfig?: (config: VideoDecoderConfig) => void;
  onVideoChunk: (chunk: EncodedVideoChunk) => void;
  onAudioChunk?: (chunk: EncodedAudioChunk) => void;
  onError?: (error: Error) => void;
  setStatus?: (type: string, message: string) => void;
  setDimensions?: (dimensions: MP4VideoTrackDimensions) => void;
  setDuration?: (duration: number) => void;
  setNumFrames?: (numFrames: number) => void;
  setMuxerOptions?: (opts: MuxerOptions<ArrayBufferTarget>) => void;
}

// Demuxes the first video track of an MP4 file using MP4Box
export class MP4Demuxer {
  private readonly _onConfig: ((config: VideoDecoderConfig) => void) | undefined;
  private readonly _onVideoChunk: (chunk: EncodedVideoChunk) => void;
  private readonly _onError: ((error: Error) => void) | undefined;
  private readonly _onAudioChunk: ((chunk: EncodedAudioChunk) => void) | undefined;
  private readonly _setStatus: ((type: string, message: string) => void) | undefined;
  private readonly _file: MP4File;
  private readonly _setDimensions: ((dimensions: MP4VideoTrackDimensions) => void) | undefined;
  private readonly _setDuration: ((duration: number) => void) | undefined;
  private readonly _setNumFrames: ((numFrames: number) => void) | undefined;
  private readonly _setMuxerOptions: ((opts: MuxerOptions<ArrayBufferTarget>) => void) | undefined;
  private _audioTrackId = -1;
  private _videoTrackId = -1;
  private _pause = false;
  private _sampleQueue: { trackId: number; sample: MP4Sample }[] = [];
  private _consumingQueue = false;

  constructor(
    sourceVideo: Blob | string | URL,
    {
      onConfig,
      onVideoChunk,
      onAudioChunk,
      onError,
      setStatus,
      setDimensions,
      setDuration,
      setNumFrames,
      setMuxerOptions,
    }: MP4DemuxerOptions
  ) {
    this._onConfig = onConfig;
    this._onVideoChunk = onVideoChunk;
    this._onError = onError;
    this._onAudioChunk = onAudioChunk;
    this._setStatus = setStatus;
    this._setDimensions = setDimensions;
    this._setDuration = setDuration;
    this._setNumFrames = setNumFrames;
    this._setMuxerOptions = setMuxerOptions;

    // Configure an MP4Box File for demuxing.
    const file = mp4box.createFile();
    this._file = file;
    file.onError = (error) => {
      setStatus?.("demux", error);
      onError?.(new Error(error));
    };
    file.onReady = this._onReady.bind(this);
    file.onSamples = this._onSamples.bind(this);

    if (sourceVideo instanceof Blob) {
      // path if video file is provided directly
      (async () => {
        const buffer: MP4ArrayBuffer = (await sourceVideo.arrayBuffer()) as MP4ArrayBuffer;
        buffer.fileStart = 0;
        file.appendBuffer(buffer);
        file.flush();
      })();
    } else {
      // path if video url is provided instead
      const fileSink = new MP4FileSink(this._file, setStatus);
      fetch(sourceVideo).then((response) => {
        response.body?.pipeTo(new WritableStream(fileSink, { highWaterMark: 2 })).then(() => {
          logger.log("on done");
        });
      });
    }
  }

  // Get the appropriate `description` for a specific track. Assumes that the
  // track is H.264, H.265, VP8, VP9, or AV1.
  private _description(track: MP4Track) {
    const trak = this._file.getTrackById(track.id);
    for (const entry of trak.mdia.minf.stbl.stsd.entries) {
      const box = entry.avcC || entry.hvcC || entry.vpcC || entry.av1C;
      if (box) {
        const stream = new DataStream(undefined, 0, DataStream.BIG_ENDIAN);
        box.write(stream);
        return new Uint8Array(stream.buffer, 8); // Remove the box header.
      }
    }
    throw new Error("avcC, hvcC, vpcC, or av1C box not found");
  }

  private _onReady(info: MP4Info) {
    logger.log("demuxer on ready", info);
    const videoTrack = info.videoTracks[0];
    const audioTrack = info.audioTracks[0];
    this._audioTrackId = audioTrack?.id ?? -1;
    this._videoTrackId = videoTrack.id;

    // logger.log(info);
    // logger.log("audio track", audioTrack);

    // this normalizes and ignores 3rd dimension portion of matrix
    console.log("track matrix", videoTrack.matrix);
    const videoMatrix = [
      videoTrack.matrix[0] / 65536,
      videoTrack.matrix[1] / 65536,
      videoTrack.matrix[3] / 65536,
      videoTrack.matrix[4] / 65536,
      videoTrack.matrix[6] / 65536,
      videoTrack.matrix[7] / 65536,
    ];
    logger.log("normalized video matrix", videoMatrix);

    const dimensions = {
      width: Math.abs(
        Math.round(
          videoMatrix[0] * videoTrack.video.width + videoMatrix[1] * videoTrack.video.height
        )
      ),
      height: Math.abs(
        Math.round(
          videoMatrix[2] * videoTrack.video.width + videoMatrix[3] * videoTrack.video.height
        )
      ),
      rawWidth: videoTrack.video.width,
      rawHeight: videoTrack.video.height,
      transform: videoMatrix,
    };
    this._setDimensions?.(dimensions);
    // logger.log("video duration", info.duration, info.timescale, info);
    const duration = info.duration / info.timescale;
    const numFrames = videoTrack.nb_samples;
    logger.log("video duration", duration);
    logger.log("num frames", numFrames);
    this._setDuration?.(duration);
    this._setNumFrames?.(numFrames);
    logger.log("video mime", info.mime);
    this._setStatus?.("demux", "Ready");

    if (this._setMuxerOptions) {
      const muxOptions: MuxerOptions<ArrayBufferTarget> = {
        target: new ArrayBufferTarget(),
        video: {
          codec: "avc",
          width: dimensions.width,
          height: dimensions.height,
        },
        audio: {
          codec: "opus",
          sampleRate: audioTrack.audio.sample_rate,
          numberOfChannels: audioTrack.audio.channel_count,
        },
        fastStart: "in-memory",
        firstTimestampBehavior: "offset",
      };
      this._setMuxerOptions(muxOptions);
    }

    // Generate and emit an appropriate VideoDecoderConfig.
    this._onConfig?.({
      // Browser doesn't support parsing full vp8 codec (eg: `vp08.00.41.08`),
      // they only support `vp8`.
      codec: videoTrack.codec.startsWith("vp08") ? "vp8" : videoTrack.codec,
      codedHeight: videoTrack.video.height,
      codedWidth: videoTrack.video.width,
      description: this._description(videoTrack),
    });

    // Start demuxing.
    this._file.setExtractionOptions(videoTrack.id, null, { nbSamples: 20 });
    if (this._audioTrackId !== -1) {
      this._file.setExtractionOptions(this._audioTrackId, null, { nbSamples: 20 });
    }
    this._file.start();
  }

  private _consumeSamplesQueue() {
    if (this._consumingQueue) {
      return;
    }
    try {
      this._consumingQueue = true;
      while (!this._pause && this._sampleQueue.length > 0) {
        const { trackId, sample } = this._sampleQueue.shift()!;

        if (trackId === this._audioTrackId && this._onAudioChunk) {
          this._onAudioChunk(
            new EncodedAudioChunk({
              type: sample.is_sync ? "key" : "delta",
              timestamp: (1e6 * sample.cts) / sample.timescale,
              duration: (1e6 * sample.duration) / sample.timescale,
              data: sample.data,
            })
          );
        }

        if (trackId === this._videoTrackId) {
          this._onVideoChunk(
            new EncodedVideoChunk({
              type: sample.is_sync ? "key" : "delta",
              timestamp: (1e6 * sample.cts) / sample.timescale,
              duration: (1e6 * sample.duration) / sample.timescale,
              data: sample.data,
            })
          );
        }
      }
    } finally {
      this._consumingQueue = false;
    }
  }

  private _onSamples(track_id: number, _ref: unknown, samples: MP4Sample[]) {
    logger.log(`demux track ${track_id}, ${samples.length} samples`);
    this._sampleQueue.push(...samples.map((sample) => ({ trackId: track_id, sample })));
    this._consumeSamplesQueue();
  }

  pause() {
    if (!this._pause) {
      this._file.stop();
      this._pause = true;
    }
  }

  resume() {
    if (this._pause) {
      this._file.start();
      this._pause = false;
      this._consumeSamplesQueue();
    }
  }

  get paused() {
    return this._pause;
  }
}
