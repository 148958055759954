import useLatest from "@react-hook/latest";
import { createCanvas } from "canvas-utils";
import { AnyCanvasRenderingContext2D } from "captions-engine";
import { DevLogger } from "dev-logger";
import { useCallback, useMemo, useRef } from "react";

import {
  EXPORT_CHECKPOINT,
  STATUS_SUCCESS,
  STATUS_ERROR,
  EXPORT_TYPE_CLIENT,
} from "~/constants/mixpanel.constants";
import { useAnalytics } from "~/hooks/useAnalytics";
import { MICROSECONDS_IN_SECOND } from "~/utils/timestamp-formatter";

import { MP4Demuxer, MP4VideoTrackDimensions } from "./MP4Demuxer";

export interface VideoFrameData {
  image: TexImageSource;
  frameNumber: number;
  width: number;
  height: number;
  /** Timestamp of the frame in microseconds. */
  timestamp: number;
  /** Duration of the frame in microseconds. */
  duration: number | null;
  frame: VideoFrame;
}

export interface FrameSourceStartData {
  dimensions: MP4VideoTrackDimensions;
  duration: number;
  numFrames: number;
}

export interface UseFrameSourceProps {
  onFrame: (frameData: VideoFrameData) => Promise<void>;
  onStart: (data: FrameSourceStartData) => Promise<void>;
  onFinish: () => void;
  onError: (error: Error) => void;
  projectId?: string;
}

const logger = new DevLogger("[frame-extraction]");

const STALLED_FRAME_COUNT = 50;

/**
 * Hook to extract frames from a video source.
 *
 * @param onFrame - Callback to be called when a frame is extracted.
 * @param onStart - Callback to be called when the frame extraction starts.
 * @param onFinish - Callback to be called when the frame extraction finishes.
 * @param onError - Callback to be called when an error occurs.
 * @returns Object with `start` and `cancel` functions to start and cancel the frame extraction.
 */
export function useFrameSource({
  onFrame,
  onStart,
  onFinish,
  onError,
  projectId,
}: UseFrameSourceProps) {
  const { track } = useAnalytics();

  const mp4Demuxer = useRef<MP4Demuxer | null>(null);
  const videoDecoder = useRef<VideoDecoder | null>(null);
  const extraTrackingInfo = useRef<Record<string, unknown>>({});

  const latestOnFrame = useLatest(onFrame);
  const latestOnStart = useLatest(onStart);
  const latestOnFinish = useLatest(onFinish);
  const latestOnError = useLatest(onError);

  const frameCorrectionCanvas = useRef<HTMLCanvasElement | OffscreenCanvas | null>(null);
  const doCorrectFrameImage = useCallback(
    (frame: VideoFrame, dimensions?: MP4VideoTrackDimensions | null): TexImageSource => {
      if (
        !dimensions ||
        (dimensions.transform[0] === 1 &&
          dimensions.transform[1] === 0 &&
          dimensions.transform[2] === 0 &&
          dimensions.transform[3] === 1)
      ) {
        return frame;
      }
      if (!frameCorrectionCanvas.current) {
        frameCorrectionCanvas.current = createCanvas(dimensions.width, dimensions.height);
      } else {
        frameCorrectionCanvas.current.width = dimensions.width;
        frameCorrectionCanvas.current.height = dimensions.height;
      }
      const ctx = frameCorrectionCanvas.current.getContext(
        "2d"
      ) as AnyCanvasRenderingContext2D | null;
      if (!ctx) {
        throw new Error("Failed to create the 2D context to correct the frame image");
      }
      ctx.clearRect(0, 0, dimensions.width, dimensions.height);
      ctx.setTransform(
        dimensions.transform[0],
        dimensions.transform[1],
        dimensions.transform[2],
        dimensions.transform[3],
        dimensions.transform[4],
        dimensions.transform[5]
      );
      ctx.drawImage(frame, 0, 0);
      return ctx.canvas;
    },
    []
  );

  const handleError = useCallback(
    (error: Error) => {
      latestOnError.current(error);
    },
    [latestOnError]
  );

  const start = useCallback(
    (videoSource: string | URL | Blob, trackingInfo?: Record<string, unknown>) => {
      if (mp4Demuxer.current || videoDecoder.current) {
        throw new Error("Already started");
      }
      extraTrackingInfo.current = trackingInfo ?? {};
      logger.log("Starting frame extraction");
      let videoDuration = 0;
      let totalFrames = 0;
      let videoDimensions: MP4VideoTrackDimensions | null = null;
      let frameQueueSize = 0;
      let processedFramesCount = 0;
      let receivedChunksCount = 0;
      // We use a promise chain to ensure that we process frames in order, even if they are decoded
      // faster than we can process them. This is done because the encoder expects frames to be
      // given with monotonically increasing timestamps.
      // This promise is also used to ensure that the frame processing only starts after the
      // onStart callback has been called.
      // The promise is initialized with a resolved promise to start the chain. Further calls need
      // to check the previous result to determine if they should continue processing.
      let frameProcessingPromise = Promise.resolve(true);
      let isFinishedExtracting = false;
      const decoder = new VideoDecoder({
        output: (frame) => {
          frameQueueSize = frameQueueSize + 1;
          frameProcessingPromise = frameProcessingPromise.then((previousResult) => {
            if (!previousResult) {
              // The previous call failed, so we don't continue processing frames
              return false;
            }
            const correctedImage = doCorrectFrameImage(frame, videoDimensions);
            if (isFinishedExtracting) {
              // don't decode frames after the expected duration of the video
              return true;
            }
            return latestOnFrame
              .current({
                image: correctedImage,
                frameNumber: processedFramesCount,
                width: videoDimensions?.width ?? frame.displayWidth,
                height: videoDimensions?.height ?? frame.displayHeight,
                timestamp: frame.timestamp,
                duration: frame.duration,
                frame,
              })
              .then(async () => {
                processedFramesCount = processedFramesCount + 1;
                // The timestamps from the frame object are in microseconds, whereas the video
                // duration is in seconds, so we convert everything to seconds.
                const frameTime = frame.timestamp / MICROSECONDS_IN_SECOND;
                const frameDuration = (frame.duration ?? 0) / MICROSECONDS_IN_SECOND;
                if (processedFramesCount % 10 === 0) {
                  logger.log(
                    `Processed ${processedFramesCount}/${totalFrames} frames (${(
                      frameTime + frameDuration
                    ).toFixed(3)}s/${videoDuration.toFixed(3)}s)`
                  );
                }
                if (
                  processedFramesCount >= totalFrames ||
                  frameTime + frameDuration >= videoDuration
                ) {
                  // set this to true to prevent any trailing frames outside of the video duration from being encoded
                  isFinishedExtracting = true;
                  // The given frame is the last one, so we finish the frame extraction and call the
                  // onFinish callback.
                  tryClose(frame);
                  tryClose(decoder);
                  mp4Demuxer.current?.pause();
                  mp4Demuxer.current = null;
                  videoDecoder.current = null;
                  latestOnFinish.current();
                } else if (
                  isCaughtUp(processedFramesCount, receivedChunksCount) &&
                  demuxer.paused
                ) {
                  // We have caught up with the demuxer, so we can resume it to continue processing.
                  demuxer.resume();
                }
              })
              .then(() => {
                const frameCount25Percent = Math.floor(totalFrames * 0.25);
                const frameCount50Percent = Math.floor(totalFrames * 0.5);
                const frameCount75Percent = Math.floor(totalFrames * 0.75);

                if (processedFramesCount === frameCount25Percent) {
                  track(
                    EXPORT_CHECKPOINT,
                    {
                      checkpoint: "processed_video_frame_25",
                      export_type: EXPORT_TYPE_CLIENT,
                      project_id: projectId,
                      status: STATUS_SUCCESS,
                      total_frames: totalFrames,
                      frame_queue_size: frameQueueSize,
                      processed_frames_count: processedFramesCount,
                      ...extraTrackingInfo.current,
                    },
                    ["mixpanel"]
                  );
                } else if (processedFramesCount === frameCount50Percent) {
                  track(
                    EXPORT_CHECKPOINT,
                    {
                      checkpoint: "processed_video_frame_50",
                      export_type: EXPORT_TYPE_CLIENT,
                      project_id: projectId,
                      status: STATUS_SUCCESS,
                      total_frames: totalFrames,
                      frame_queue_size: frameQueueSize,
                      processed_frames_count: processedFramesCount,
                      ...extraTrackingInfo.current,
                    },
                    ["mixpanel"]
                  );
                } else if (processedFramesCount === frameCount75Percent) {
                  track(
                    EXPORT_CHECKPOINT,
                    {
                      checkpoint: "processed_video_frame_75",
                      export_type: EXPORT_TYPE_CLIENT,
                      project_id: projectId,
                      status: STATUS_SUCCESS,
                      total_frames: totalFrames,
                      frame_queue_size: frameQueueSize,
                      processed_frames_count: processedFramesCount,
                      ...extraTrackingInfo.current,
                    },
                    ["mixpanel"]
                  );
                } else if (processedFramesCount === totalFrames) {
                  track(
                    EXPORT_CHECKPOINT,
                    {
                      checkpoint: "processed_video_frame_100",
                      export_type: EXPORT_TYPE_CLIENT,
                      project_id: projectId,
                      status: STATUS_SUCCESS,
                      total_frames: totalFrames,
                      frame_queue_size: frameQueueSize,
                      processed_frames_count: processedFramesCount,
                      ...extraTrackingInfo.current,
                    },
                    ["mixpanel"]
                  );
                }
                return true;
              }) // Resolve to true if we successfully processed the frame
              .catch((error) => {
                handleError(new Error("Error while processing the video frame", { cause: error }));
                const processedFramePercent = Math.floor(
                  100 * (processedFramesCount / totalFrames)
                );
                track(
                  EXPORT_CHECKPOINT,
                  {
                    checkpoint: "processed_video_frame",
                    export_type: EXPORT_TYPE_CLIENT,
                    project_id: projectId,
                    status: STATUS_ERROR,
                    error: error instanceof Error ? error.message : "error processing video frame",
                    total_frames: totalFrames,
                    frame_queue_size: frameQueueSize,
                    processed_frames_count: processedFramesCount,
                    processed_frame_percent: processedFramePercent,
                    ...extraTrackingInfo.current,
                  },
                  ["mixpanel"]
                );
                return false; // Return false to stop processing frames
              })
              .finally(() => {
                tryClose(frame);
                frameQueueSize = frameQueueSize - 1;
              });
          });
        },
        error: (error) => {
          handleError(new Error("Error while decoding the video", { cause: error }));
          const processedFramePercent = Math.floor(100 * (processedFramesCount / totalFrames));
          track(
            EXPORT_CHECKPOINT,
            {
              checkpoint: "decoding",
              export_type: EXPORT_TYPE_CLIENT,
              project_id: projectId,
              status: STATUS_ERROR,
              error: error.message,
              total_frames: totalFrames,
              frame_queue_size: frameQueueSize,
              processed_frames_count: processedFramesCount,
              processed_frame_percent: processedFramePercent,
              ...extraTrackingInfo.current,
            },
            ["mixpanel"]
          );
        },
      });

      const demuxer = new MP4Demuxer(videoSource, {
        onConfig: (config) => {
          const previousState = decoder.state; // should always be "unconfigured"
          try {
            decoder.configure(config);
          } catch (error) {
            handleError(new Error("Error while configuring the video decoder", { cause: error }));
            track(
              EXPORT_CHECKPOINT,
              {
                checkpoint: "configured_decoder",
                export_type: EXPORT_TYPE_CLIENT,
                project_id: projectId,
                status: STATUS_ERROR,
                error: error instanceof Error ? error.message : "error configuring decoder",
                decoder_state: decoder.state,
                previous_decoder_state: previousState,
                ...extraTrackingInfo.current,
                configuration: {
                  codec: config.codec,
                  coded_width: config.codedWidth,
                  coded_height: config.codedHeight,
                },
              },
              ["mixpanel"]
            );
            frameProcessingPromise = frameProcessingPromise.then(() => false);
            return;
          }
          track(
            EXPORT_CHECKPOINT,
            {
              checkpoint: "configured_decoder",
              export_type: EXPORT_TYPE_CLIENT,
              project_id: projectId,
              status: STATUS_SUCCESS,
              decoder_state: decoder.state,
              previous_decoder_state: previousState,
              ...extraTrackingInfo.current,
              configuration: {
                codec: config.codec,
                coded_width: config.codedWidth,
                coded_height: config.codedHeight,
              },
            },
            ["mixpanel"]
          );
          frameProcessingPromise = frameProcessingPromise.then(
            async (previousResult): Promise<boolean> => {
              if (!previousResult) {
                return false;
              }
              if (!videoDimensions) {
                handleError(new Error("No video dimensions available"));
                return false;
              }
              return latestOnStart
                .current({
                  dimensions: videoDimensions,
                  duration: videoDuration,
                  numFrames: totalFrames,
                })
                .then(() => {
                  track(
                    EXPORT_CHECKPOINT,
                    {
                      checkpoint: "started_frame_extraction",
                      export_type: EXPORT_TYPE_CLIENT,
                      project_id: projectId,
                      status: STATUS_SUCCESS,
                      decoder_state: decoder.state,
                      ...extraTrackingInfo.current,
                    },
                    ["mixpanel"]
                  );

                  return true;
                })
                .catch((error) => {
                  handleError(
                    new Error("Error while starting the frame extraction", { cause: error })
                  );
                  track(
                    EXPORT_CHECKPOINT,
                    {
                      checkpoint: "started_frame_extraction",
                      export_type: EXPORT_TYPE_CLIENT,
                      project_id: projectId,
                      status: STATUS_ERROR,
                      error:
                        error instanceof Error ? error.message : "error starting frame extraction",
                      decoder_state: decoder.state,
                      ...extraTrackingInfo.current,
                    },
                    ["mixpanel"]
                  );
                  return false;
                });
            }
          );
        },
        onVideoChunk: (chunk) => {
          receivedChunksCount = receivedChunksCount + 1;
          decoder.decode(chunk);
          const chunkCount25Percent = Math.floor(totalFrames * 0.25);
          const chunkCount50Percent = Math.floor(totalFrames * 0.5);
          const chunkCount75Percent = Math.floor(totalFrames * 0.75);

          if (receivedChunksCount === chunkCount25Percent) {
            track(
              EXPORT_CHECKPOINT,
              {
                checkpoint: "decoded_chunk_25",
                export_type: EXPORT_TYPE_CLIENT,
                project_id: projectId,
                status: STATUS_SUCCESS,
                received_chunks_count: receivedChunksCount,
                total_frames: totalFrames,
                decoder_state: decoder.state,
                ...extraTrackingInfo.current,
              },
              ["mixpanel"]
            );
          } else if (receivedChunksCount === chunkCount50Percent) {
            track(
              EXPORT_CHECKPOINT,
              {
                checkpoint: "decoded_chunk_50",
                export_type: EXPORT_TYPE_CLIENT,
                project_id: projectId,
                status: STATUS_SUCCESS,
                received_chunks_count: receivedChunksCount,
                total_frames: totalFrames,
                decoder_state: decoder.state,
                ...extraTrackingInfo.current,
              },
              ["mixpanel"]
            );
          } else if (receivedChunksCount === chunkCount75Percent) {
            track(
              EXPORT_CHECKPOINT,
              {
                checkpoint: "decoded_chunk_75",
                export_type: EXPORT_TYPE_CLIENT,
                project_id: projectId,
                status: STATUS_SUCCESS,
                received_chunks_count: receivedChunksCount,
                total_frames: totalFrames,
                decoder_state: decoder.state,
                ...extraTrackingInfo.current,
              },
              ["mixpanel"]
            );
          }
          if (receivedChunksCount >= totalFrames) {
            logger.warn("Final chunk received, finishing the frame extraction");
            track(
              EXPORT_CHECKPOINT,
              {
                checkpoint: "final_chunk_received",
                export_type: EXPORT_TYPE_CLIENT,
                project_id: projectId,
                status: STATUS_SUCCESS,
                received_chunks_count: receivedChunksCount,
                total_frames: totalFrames,
                decoder_state: decoder.state,
                ...extraTrackingInfo.current,
              },
              ["mixpanel"]
            );
            decoder.flush().catch(() => {
              // Since the decoder might be closed during the flush, we ignore the error
            });
          } else if (isStalled(processedFramesCount, receivedChunksCount)) {
            // We are receiving frames faster than we can process them. Pause the demuxer to catch up.
            demuxer.pause();
          }
        },
        onError: (error) => {
          handleError(new Error("Error while demuxing the video", { cause: error }));
        },
        setDimensions: (dimensions) => {
          videoDimensions = dimensions;
        },
        setDuration: (duration) => {
          videoDuration = duration;
        },
        setNumFrames: (numFrames) => {
          totalFrames = numFrames;
        },
      });

      mp4Demuxer.current = demuxer;
      videoDecoder.current = decoder;
    },
    [
      doCorrectFrameImage,
      handleError,
      latestOnFinish,
      latestOnFrame,
      latestOnStart,
      projectId,
      track,
    ]
  );

  const cancel = useCallback(() => {
    mp4Demuxer.current?.pause();
    if (tryClose(videoDecoder.current)) {
      track(
        EXPORT_CHECKPOINT,
        {
          checkpoint: "canceled_decoder",
          export_type: EXPORT_TYPE_CLIENT,
          project_id: projectId,
          status: STATUS_ERROR,
          decoder_state: videoDecoder.current?.state,
          ...extraTrackingInfo.current,
        },
        ["mixpanel"]
      );
    }
    mp4Demuxer.current = null;
    videoDecoder.current = null;
  }, [projectId, track]);

  return useMemo(() => ({ start, cancel }), [start, cancel]);
}

/**
 * Tries to close an object without returning any errors
 * @param obj - The object to close
 * @returns True if the object is valid and was closed
 */
function tryClose(obj?: { close(): void } | null) {
  try {
    obj?.close();
    return Boolean(obj);
  } catch {
    // Ignore errors while closing the object, e.g. if it has already been closed
    return false;
  }
}

function isStalled(processedFramesCount: number, receivedChunksCount: number) {
  return receivedChunksCount - processedFramesCount > STALLED_FRAME_COUNT;
}

function isCaughtUp(processedFramesCount: number, receivedChunksCount: number) {
  return receivedChunksCount - processedFramesCount > STALLED_FRAME_COUNT / 2;
}
